import React from 'react';
import './NavbarLink.styles.css';

function NavbarLink({children}: {children:string}) {
  return (
    <a
      href="https://dev.begroovy.com.br"
      className="text-light block rounded px-3 py-2 transition duration-100 md:border-0 md:p-0"
    >
      {children}
    </a>
  );
}

export default NavbarLink;
