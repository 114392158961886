import './Home.styles.css';
import Navbar from '../../components/Navbar/Navbar'
import Servicos from '../../components/Section/Servicos/Servicos';
import SobreNos from '../../components/Section/SobreNos/SobreNos';
import Carrossel from '../../components/Carrossel/Carrossel';

function Home() {
  return (
    <div className="Home">
      <Navbar />
      <Carrossel />
      <Servicos />
      <SobreNos/>
    </div>
  );
}

export default Home;
