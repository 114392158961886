import React from 'react';
import MainButton from '../../Buttons/MainButton/MainButton';

const SobreNos = () => {
  return (
    <section className="bg-background-900 py-12 text-white">
      <div className="mx-auto flex flex-col items-center">
        {/* Texto da Esquerda */}
        <div className="flex w-full flex-col md:items-end items-center">
          <h2 className="md:w-2/5 text-3xl mb-4 text-start font-bold">
            Quem somos?
          </h2>
          <div className="mb-6 md:w-2/5 w-5/6 border-b-4 border-pink-500"></div>
        </div>

        <div className="flex w-full flex-col-reverse items-center justify-between md:flex-row">
          {/* Imagem */}

          {/* Texto e botão */}
          <div className="mb-8 flex flex-col justify-between px-4 md:w-1/2 md:px-12">
            <p className="mb-6 font-main text-lg font-medium">
              "At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga." "At vero eos et accusamus
              et iusto odio dignissimos ducimus qui blanditiis praesentium
              voluptatum deleniti atque corrupti quos dolores et quas molestias
              excepturi sint occaecati cupiditate non provident, similique sunt
              in culpa qui officia deserunt mollitia animi, id est laborum et
              dolorum fuga."
            </p>
            <MainButton>Entre em contato</MainButton>
          </div>

          <div className="mb-8 flex items-center justify-center md:mb-0 md:w-1/2">
            <img
              src="https://placehold.co/950x685/21172f/white"
              alt="Placeholder"
              className="h-auto w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SobreNos;
