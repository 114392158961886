import React, {useState} from 'react';
import './Navbar.styles.css';
import logo from '../../assets/logo.png';
import NavbarLink from './NavBarLink/NavbarLink';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="border-gray-200 bg-background-900">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
        {/* LOGO */}
        <div className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-20" alt="BeGroovy Logo" />
        </div>

        {/* BOTÃO COLAPSÁVEL */}
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Abrir Menu Principal</span>
          <svg
            className="h-5 w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        {/* ITENS */}
        <div
          className={`w-full bg-background-900 md:block md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`}
          id="navbar-default"
        >
          <ul className="links-hover mt-4 flex flex-col rounded-lg border border-background-800 bg-background-900 p-4 font-main text-xl font-semibold md:mt-0 md:flex-row md:space-x-8 md:border-0 md:p-0 rtl:space-x-reverse">
            <li>
              <NavbarLink>Home</NavbarLink>
            </li>
            <li>
              <NavbarLink>Soluções</NavbarLink>
            </li>
            <li>
              <NavbarLink>Sobre Nós</NavbarLink>
            </li>
            <li>
              <NavbarLink>Contato</NavbarLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
