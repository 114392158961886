import React from 'react';
import './MainButton.styles.css';

function MainButton({children}: {children: string}) {
  return (
    <button className="font-semibold mx-auto w-full rounded-full bg-secondary px-4 py-2 font-main text-xl text-dark md:w-1/2">
      {children}
    </button>
  );
}

export default MainButton;
