import React from 'react';
import './Carrossel.styles.css';

function Carrossel() {
  return (
    <div className='bg-gray-100 carrossel'>
    </div>
  );
}

export default Carrossel;
