import React from 'react';
import './Servicos.styles.css';
import MainButton from '../../Buttons/MainButton/MainButton';

const Servicos = () => {
  return (
    <section className="h-full w-full bg-background-900 text-light">
      <div className="py-12 md:py-24">
        <h2 className="w-full py-2 text-center font-main text-xl font-semibold text-light md:w-3/5 md:text-end md:text-3xl lg:w-2/5">
          O que podemos fazer para você?
        </h2>
        <div className="mb-4 w-full border-b-4 border-secondary md:w-2/5"></div>
      </div>
      <div className="bg-background-900">
        <div className="servicos-cards grid grid-cols-1 gap-y-8 text-left font-main text-xl font-semibold md:gap-y-4 md:text-2xl lg:grid-cols-3 lg:text-3xl">
          <div className="flex flex-col justify-between gap-y-4 bg-background-800 p-8 md:p-10 lg:p-14">
            <div className="w-full md:w-1/2">
              <h2 className="leading-relaxed">Sites & Landing Pages</h2>
              <div className="border-b-4 border-secondary py-2"></div>
            </div>
            <MainButton>Saiba Mais</MainButton>
          </div>
          <div className="flex flex-col gap-y-4 bg-background-700 p-8 md:p-10 lg:p-14">
            <div className="w-full md:w-1/2">
              <h2 className="leading-relaxed">Sistema Web e Aplicativos</h2>
              <div className="border-b-4 border-secondary py-2"></div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 bg-background-600 p-8 md:p-10 lg:p-14">
            <div className="w-full md:w-2/3">
              <h2 className="leading-relaxed">
                Branding, manual de marca, logos.
              </h2>
              <div className="border-b-4 border-secondary py-2"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Servicos;
